import { createTheme } from '@mui/material/styles';

// Define un tema personalizado
const theme = createTheme({
  palette: {
    generator: {
      bg_main: '#1B4332',
    },
    // Puedes definir más colores aquí
  },
  // A  ñade más personalizaciones aquí si es necesario
});
export { theme };
